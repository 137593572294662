<template>
  <div>
    <loadingTela v-if="carregando"/>
      <v-card-text>
        <v-row>
          <v-col>
              <v-text-field
              v-model="modelo"
              counter
              maxlength="200"
              hint=""
              :label="'https://cedeppe.global/' + modelo"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <v-text-field
              v-model="capa"
              counter
              maxlength="200"
              hint=""
              label="foto de capa"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <v-text-field
              v-model="titulo"
              counter
              maxlength="200"
              hint=""
              label="Titulo"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="tipos"
              v-model="tipo"
              name="Id"
              item-text="Nome"
              :item-value="'Id'"
              label="Tipo"
              />
          </v-col>
        </v-row>
        <v-row v-if="tipo === 3">
          <v-col>
            <v-select
              :items="regioes"
              v-model="regiao"
              name="Id"
              item-text="Nome"
              :item-value="'Id'"
              label="Região"
              />
          </v-col>
        </v-row>
        <v-row v-if="tipo == 4">
          <v-col>
              <v-text-field
              v-model="preco"
              counter
              maxlength="200"
              hint=""
              label="Preco (usar separadores com '.' e não usar 'R$', exemplo: 100.00)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="cordenadores"
              v-model="cordenador"
              name="Id"
              multiple
              item-text="Nome"
              :item-value="'Id'"
              label="Selecione o Cordenador"
              />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <quill-editor
                v-model='descricao'
                ref='textEditor'
                :options='editorOption'
              ></quill-editor>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <v-btn
                color="blue darken-1"
                text
                @click="salvarPagina()"
              >
                Salvar
              </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    <v-snackbar  v-model="snackbar">
      {{ msgSnack }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
        Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import main from '@/assets/scripts/main.js';
export default {
  name: 'NovaPagina',
  components: {
    loadingTela: () => import("@/components/layout/loadingTela"),
  },
  data: () => ({
    tipos: [],
    tipo: null,

    regioes: [],
    regiao: null,

    cordenadores: [],
    cordenador: [],

    snackbar: false,
    msgSnack: '',
    editorOption: { /* quill options */ },
    modelo: '',
    capa: '',
    titulo: '',
    preco: '',
    descricao: '',
    carregando: true,
  }),
  watch: {
    async "modelo"(valor) {
      this.carregando = true;
      await this.pegaModelo(valor)
      this.carregando = false;
    },
  },
  async created(){
    this.carregando = true;
    //pega Usuarios
    var req = {}
    var resp = await main.postApi(req, "admin/pegarUsuarios");
    var retorno = await resp.json();
    if(retorno.sucesso){
      this.tipos = [
        {
          Id: 1,
          Nome: "Capital Humano"
        },
        {
          Id: 2,
          Nome: "Universo Industrial"
        },
        {
          Id: 3,
          Nome: "Regionais"
        },
        {
          Id: 4,
          Nome: "Treinamentos"
        }
      ],
      this.regioes = [
        {
          Id: 1,
          Nome: "Nacional"
        },
        {
          Id: 2,
          Nome: "Internacional"
        },
      ],
      this.cordenadores = retorno.corpo
    }
      this.carregando = false;
  },
  methods: {
    async salvarPagina(){
      this.carregando = true;
      var req = {
        modelo: this.modelo,
        titulo: this.titulo,
        descricao: this.descricao,
        cordenadores: this.cordenador,
        tipo: this.tipo,
        regiao: this.regiao,
        preco: this.preco,
        capa: this.capa
      };
      var resp = await main.postApi(req, "admin/addPagina");
      var retorno = await resp.json();
      if(retorno.sucesso){
        this.msgSnack = 'Pagina Atualizada/Criada com sucesso'
        this.snackbar = true;
      }
      else{
        this.msgSnack = retorno.corpo.erros
        this.snackbar = true;
      }
      this.carregando = false;

      this.modelo = '';
      this.titulo = '';
      this.descricao = '';
      this.cordenador = [];
      this.tipo = null;
      this.regiao = null;
    },

    async pegaModelo(modelo){
      var req = {
        modelo: modelo
      }
      var resp = await main.postApi(req, "paginas/pegaInfosPagina");
      var retorno = await resp.json();
      if(retorno.sucesso){

        this.cordenador = retorno.corpo.Cordenadores;

        this.modelo = retorno.corpo.modelo;
        this.descricao = retorno.corpo.Descricao;
        this.titulo = retorno.corpo.Titulo;
        this.tipo = retorno.corpo.Tipo;
        this.regiao = retorno.corpo.Regiao;
        this.preco = retorno.corpo.Preco;
        this.capa = retorno.corpo.nomeFoto;
      }
      else{
        this.titulo = '';
        this.descricao = '';
        this.cordenador = [];
        this.tipo = null;
        this.regiao = null;
        this.capa = null;
      }
    },
  }
}
</script>
