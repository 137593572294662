<template>
  <v-card style="padding-top: 20px;">
    <v-tabs
      v-model="tab"
      bg-color="primary"
      style="margin-left: 30px;"
    >
      <v-tab>Nova Página</v-tab>
      <v-tab>Deletar Página</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item>
          <NovaPagina/>
        </v-window-item>

        <v-window-item>
          <RemoverPagina />
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script>

import NovaPagina from "@/components/NovaPagina";
import RemoverPagina from "@/components/RemoverPagina";

export default {
  name: 'Home',
  components: {
    loadingTela: () => import("@/components/layout/loadingTela"),
    NovaPagina,
    RemoverPagina
  },
  data: () => ({
    tab: null,
  }),
  watch: {

  },
  async created(){

  },
  methods: {

  }
}
</script>
