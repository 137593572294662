
//const apiUrl = 'https://us-central1-cedeppe-api-7db6d.cloudfunctions.net/app/';
//const apiUrl = 'http://localhost:8080/cedeppe-api-7d67d/us-central1/app/';

//novo
const apiUrl = 'https://us-central1-cedeppe-api-7d67d.cloudfunctions.net/app/';


//const frontUrl = 'https://cedeppe.global/';
const frontUrl = 'localhost:8080/';
export default {
	async postApi(req, api) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(req)
        };
        var caminho = apiUrl + api;
        return  await fetch(caminho, requestOptions);
	},
    async getApi(req, api) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(req)
        };
        var caminho = apiUrl + api;
        return  await fetch(caminho, requestOptions);
	},
    async getApiExterna(req, api) {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        var caminho =  api;
        return  await fetch(caminho, requestOptions);
	},
    dataAtualFormatada(dataEnviada){
        dataEnviada = dataEnviada.split('T')[0].replace('-','/').replace('-','/');
        var dia =  new Date(dataEnviada).getDate().toString().length == 1 ? '0' + new Date(dataEnviada).getDate() : new Date(dataEnviada).getDate();
        var mes =  (new Date(dataEnviada).getMonth() + 1 ).toString().length == 1 ? '0' + (new Date(dataEnviada).getMonth() + 1) : new Date(dataEnviada).getMonth() + 1;
        var ano =  new Date(dataEnviada).getFullYear();
        dataEnviada = dia + '/' + mes + '/' + ano
        return dataEnviada;
    },
    dataUrl(foto, tipoSRC = 0){
        if(tipoSRC == 0){
            if(foto != null){
                console.log('data:image/jpeg;base64,' + btoa( new Uint8Array(foto.data).reduce((data, byte) => data + String.fromCharCode(byte), '')))
                return 'data:image/jpeg;base64,' + btoa( new Uint8Array(foto.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            }
            else{
                return foto;
            }
        }
        else{
            return foto;
        }
    },
    irURL(caminho){
        window.location.assign(frontUrl + caminho);
    },
}

