<template>
  <div>
    <loadingTela v-if="carregando"/>
    <v-card
      :loading="loading"
      class="mx-auto my-12"
      max-width="900"
    >
<form style="margin: 20px">
      <v-text-field
        v-model="nome"
        label="Nome Completo"
        required
      ></v-text-field>
      <v-text-field
        v-model="email"
        label="Email (mesmo utilizado no pagamento)"
        required
      ></v-text-field>
      <v-text-field
        v-model="celular"
        label="Telefone/Celular"
        v-mask="'(##) #####-####'"
        required
      ></v-text-field>
      <v-textarea
        v-model="conta"
        label="Conta bancaria/PIX para recebimentos"
        required
      ></v-textarea>

  <quill-editor
    v-model="descricao"
    ref='textEditor'
    :options='editorOption'
  ></quill-editor>

      <v-btn
        v-if="salvarBotao"
        class="mr-4 primary"
        style="float: right; margin-bottom: 30px; margin-top: 30px"
        @click="salvarUsuario"
      >
        Salvar
      </v-btn>
    </form>
    </v-card>

  <v-dialog
    v-model="dialogSucesso"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-card-text>
            <strong class="text-h6">Parabéns !</strong></br>
            <p>
              Seus Dados foram enviados a nossa equipe !
            </p>
          </v-card-text>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="desabilitar"
          @click="ir"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="dialogPreencher"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-card-text>
            <strong class="text-h6"></strong></br>
            <p>
              Preencha todos os campos
            </p>
          </v-card-text>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="desabilitar"
          @click="dialogPreencher = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  </div>
</template>

<script>
  import main from '@/assets/scripts/main.js';
  export default {
    name: 'Home',
    components: {
      loadingTela: () => import("@/components/layout/loadingTela"),
      siderbar: () => import("@/components/layout/sidebar"),
    },
    computed : {
      
    },
    data: () => ({
      desabilitar: false,
      dialogPreencher: false,
      textoDialog: null,
      dialogSucesso: false,
      loading: true,
      modelo: null,
      carregando: false,
      editorOption: { /* quill options */ },
      salvarBotao: false,
      nome: null,
      email: null,
      celular: null,
      conta: null,
      cupomCriado: null,
      descricao: 'Breve descrição sobre você'
    }),
    watch: {
      async "email"(valor) {
        if(this.modelo != 'LABIN300'){
          this.carregando = true;
          if(await this.verificaEmail()){
            this.salvarBotao = true;
          }
          else{
            this.salvarBotao = false;
          }
          this.carregando = false;
        }
        else{
           this.carregando = false;
           this.salvarBotao = true;
        }
      }
    },
    async created(){
      this.carregando = true;
      const params = new URLSearchParams(window.location.search)
      if(params.get('modelo') != null){
        this.modelo = params.get('modelo');
      }
      else{
        
      }
      this.carregando = false;
    },
    methods: {
      dataUrlTra(imagem){
          return 'data:image/jpeg;base64,' + btoa( new Uint8Array(imagem).reduce((data, byte) => data + String.fromCharCode(byte), ''));
      },

      async verificaEmail(){
        var req = {
          email: this.email
        };
        var resp = await main.postApi(req, "mercadopago/verificaPagamentoAprovado");
        var retorno = await resp.json();
        return retorno.sucesso;
      },

      async salvarUsuario(){

        if(this.nome != null && this.email != null && this.descricao != null && this.conta != null && this.celular != null){
           const myArray = this.nome.split(" ");
            var novoCupom = "";
            myArray.forEach(function(nome, i) {
                novoCupom = novoCupom + nome[0];
            })
            
            this.cupomCriado = novoCupom + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10);
            var req = {
              id: '0',
              nome: this.nome,
              cupom: this.cupomCriado,
              email: this.email,
              descricao: this.descricao,
              conta: this.conta,
              foto: null,
              celular: this.celular
            };
            var resp = await main.postApi(req, "admin/EditarUsuario");
            var retorno = await resp.json();

            if(retorno.sucesso){
              this.textoDialog = "Parabens você redirecionado para sua pagina de associado "
              this.dialogSucesso = true;
            }
        }
        else{
          this.dialogPreencher = true;
        }
       
      },

      async ir(){
        window.location.assign("https://cedeppe.global/Consultor?modelo=" + this.cupomCriado);
      },
    }
  }
</script>
