<template>
    <div>
      <loadingTela v-if="carregando"/>
      <v-row>
        <v-col cols="12" lg="9" v-if="user != null">
          <v-card  style="padding: 20px">
            <v-card-text>
              <strong class="text-h6">{{(usuario.Nome ? usuario.Nome : "") + (usuario.Sobrenome ? ' ' + usuario.Sobrenome : '')}}</strong>
              <p>
                {{ usuario.Email ? `email: ${usuario.Email}` : "" }}
                </br>
                {{ usuario.Telefone && usuario.Telefone !== "undefined" ? `telefone: ${usuario.Telefone}` : "" }}
              </p>
              <div v-html="usuario.Descricao"></div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <div>
            <siderbar />
          </div>
        </v-col>
       </v-row>
    </div>
</template>

<script>
    import main from '@/assets/scripts/main.js';

    export default {
        name: 'UserPage',
        components: {
            loadingTela: () => import("@/components/layout/loadingTela"),
            siderbar: () => import("@/components/layout/sidebar"),
        },
        data: () => ({
            usuario: {},
            infosModelo: {},
            carregando: false
        }),
        async created(){
          this.carregando = true;
          const params = new URLSearchParams(window.location.search)

          if(params.get('user') != null){
              this.user = params.get('user');
              localStorage.setItem('origem', this.user);
              console.log(this.user)
              var req = { user: this.user };
              var resp = await main.postApi(req, "paginas/pegarPaginaUsuario");
              var retorno = await resp.json();
              if (retorno.sucesso) {this.infosModelo = retorno.corpo }

              resp = await main.postApi(req, "admin/pegarUsuario");
              retorno = await resp.json();
              if (retorno.sucesso) {this.usuario = retorno.corpo }

              console.log(this.usuario)
              console.log(this.infosModelo)
              this.carregando = false;
          }
          else{
            this.$router.push('Index')
          }
        },
    }
</script>
