<template>
  <v-app style="background-color: #f8f9fe">
    <AppBar />
    <v-main>
      <v-container>
        <router-view :linguaSelecionada="linguaSelecionada"></router-view>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import main from '@/assets/scripts/main.js';

export default {
  name: 'app',
  components: {
    AppBar: () => import('@/components/layout/appbar.vue'),
    Footer: () => import("@/components/layout/footer.vue"),
  },
  data: () => ({
    linguaSelecionada: 'pt',
  }),
  async created(){
    var path = window.location.pathname.replace('/','');

    if(path == ''){
      this.$router.push('Index');
    } else {
      var req = { modelo: path }

      // Verify if Article page
      var resp = await main.postApi(req, "paginas/verificaPagina");
      var retorno = await resp.json();

      if(retorno.sucesso){
        this.$router.push('Home?modelo=' + retorno.corpo.modelo);
      } else {
        // Verify if User page
        var resp = await main.postApi(req, "paginas/verificaPaginaUsuario");
        var retorno = await resp.json();

        if(retorno.sucesso){
          this.$router.push('User?user=' + retorno.corpo.Usuario);
        }
      }

    }

  }
}
</script>

<style>
</style>
