<template>
  <div>
    <loadingTela v-if="carregando"/>
    <v-row>
      <v-col cols="12" lg="12" v-if="modelo != null">
        <v-card>
          <v-img
            :src="infosModelo.FotoCapa == null ? infosModelo.nomeFoto : dataUrl(infosModelo.FotoCapa)"
            gradient="to top, rgba(25,32,72,.7), rgba(25,32,72,.0)"
            style="width: 100% !important; height: 500px"
            dark
          >
            <v-card-text class="fill-height d-flex align-end">
              <v-row class="flex-column">
                <v-col>
                  <v-btn color="red"  @click="pedirCodigo()">Associar</v-btn>
                </v-col>
                <v-col cols="12" md="10" lg="8" xl="7">
                  <h2 class="text-h3 py-3" style="line-height: 1.2">
                   {{infosModelo != null ? infosModelo.Titulo : ''}}
                  </h2>
                </v-col>

              </v-row>
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="9" v-if="modelo != null">
        <v-card  style="padding: 20px">
          <v-card-text>
             <v-btn  v-if="infosModelo.Tipo == 4" color="blue" style="color: white;  float: right;"  @click="participarTreinamento(infosModelo.modelo)">Participar</v-btn>
             <strong class="text-h6">{{infosModelo != null ? infosModelo.TituloTexto : ''}}</strong>
              <p class="card-text" v-html="infosModelo != null ? infosModelo.Descricao : ''" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="9" v-if="modelo == null">
        <v-card  style="padding: 20px">
          <v-card-text>
              <strong>Visite nossos núcleos:</strong></br></br>
              <div v-for="i in infosModelo != null ? infosModelo.Descricao : []">
                <a class="card-text" @click="ir(i.modelo)">{{i.Titulo}}</a>
              </div>
          </v-card-text>
        </v-card>
      </v-col>
       <v-col cols="12" lg="3">
      <div>

  <v-card style="padding: 15px">
    <div>
      <h4 class="text-h6 font-weight-medium pb-4" v-if="modelo != null">Coordenadores</h4>
      <div>
        <v-row v-if="modelo != null" v-for="i in infosModelo != null ? infosModelo.Cordenadores : []" :key="i.IdPaginas">

          <v-col cols="12" lg="2" >
            <v-avatar v-if="i.FotoPerfil != null">
              <img :src="dataUrl(i.FotoPerfil)">
            </v-avatar>
            <v-avatar v-if="i.FotoPerfil == null">
              <img src="@/assets/noavatar.jpg">
            </v-avatar>


            </v-col>
            <v-col cols="12" lg="10">
              <div style="margin-left: 10px">
                <h4 class="font-weight: none;">{{i.Nome + ' ' + (i.Sobrenome != null ? i.Sobrenome : '')  }} </h4>
                <h6 class="font-weight: none;">{{ (i.Email != null ? i.Email : '') }} </h6>
              </div>
            </v-col>

          <v-divider></v-divider>
        </v-row>
      </div>
    </div>
   </v-card>
        </div>
        <div>
          <siderbar />
        </div>
      </v-col>
     </v-row>
  </div>
</template>

<script>
  import main from '@/assets/scripts/main.js';
  export default {
    name: 'Home',
    components: {
      loadingTela: () => import("@/components/layout/loadingTela"),
      siderbar: () => import("@/components/layout/sidebar"),
    },
    data: () => ({
      fotoCapa: null,
      nomeFoto: null,
      modelo: null,
      infosModelo: {
        FotoCapa: '../assets/acordo.png'
      },
      carregando: false
    }),
    async created(){
      this.carregando = true;
      const params = new URLSearchParams(window.location.search)
      if(params.get('modelo') != null){
        localStorage.setItem('origem', params.get('modelo'));
        this.modelo = params.get('modelo');
        var req = {
          modelo: this.modelo
        }
        var resp = await main.postApi(req, "paginas/pegaInfosPagina");
        var retorno = await resp.json();
        console.log(retorno)
        if(retorno.sucesso){
          this.infosModelo = retorno.corpo
        }
        this.carregando = false;
      }
      else{
        this.$router.push('Index')
      }
    },
    methods: {
      dataUrl(foto){
        if(this.modelo != null){
          return main.dataUrl(foto, 0)
        }
        else{
          return main.dataUrl(foto, 1)
        }
      },
      pedirCodigo(){
       this.$router.push('Pagamento?tipo=Associacao')
      },
      ir(caminho){
        window.location.assign("https://cedeppe.global/" + caminho);
      },
      participarTreinamento(caminho){
         window.location.assign("https://cedeppe.global/Pagamento?tipo=" + caminho);
      }
  }
  }
</script>
