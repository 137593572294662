<template>
  <div style="text-align:center">
    <v-progress-linear :active="loading" color="red" indeterminate rounded absolute height="5"></v-progress-linear>
      <div style="text-align: left;">
        <v-btn color="red" dark style="margin-top: 30px; margin-left: 20px;" @click="dialogAddArtigo=true">
          Novo Artigo
        </v-btn>
      </div>
      <v-dialog
        v-model="dialogAddArtigo"
        persistent
        width="1200"
        height="1200"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Novo Artigo</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                   <v-text-field
                    v-model="titulo"
                    counter
                    maxlength="200"
                    hint=""
                    label="Titulo"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <quill-editor
                    v-model='conteudo'
                    ref='textEditor'
                    :options='editorOption'
                  ></quill-editor>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogAddArtigo = false"
            >
              Fechar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="salvarArtigo()"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogRemoveArtigo"
        persistent
        width="1200"
        height="1200"
      >
      <v-card>
          <v-card-title>
            <span class="text-h5">Remover Artigo</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <h4>{{ articleToBeRemoved }}</h4>
              <p>Têm certeza que deseja remover artigo '{{ articleToBeRemoved }}'? Essa ação é irreversível.</p>
            </v-container>
          </v-card-text>
          <v-card-actions>
              <v-btn variant="tonal" style="margin: 5px;" @click="cancelRemove()">
              Cancelar
            </v-btn>
            <v-btn color="red" style="color: white; margin: 5px;" variant="tonal" @click="removeArtigo()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-divider style="margin: 20px"></v-divider>
    <v-list-item
      v-for="artigo in artigos"
      :key="artigo.Id"
    >
      <v-list-item-content>
      <v-row>
        <v-col style="text-align: left; margin-left: 20px;">
          <v-list-item-title v-text="artigo.Titulo" style="font-weight: bold"></v-list-item-title>
          <v-list-item-subtitle v-text="'Por: ' + artigo.Nome + ' ' + (artigo.Sobrenome !== null ? artigo.Sobrenome : '')"></v-list-item-subtitle>
          <v-list-item-subtitle v-text="artigo.DtCriacao.replace('T', ' ').split('.')[0]"></v-list-item-subtitle>
          <v-list-item-subtitle v-text="'https://cedeppe.global/Artigo?id=' + artigo.Id"></v-list-item-subtitle>
        </v-col>
        <v-col style="text-align: right;">
          <v-btn  variant="tonal" style="margin: 5px" @click="selectToBeRemoved(artigo)">
            Remover
          </v-btn>
        </v-col>
      </v-row>
      <v-divider style="margin: 10px"></v-divider>
      </v-list-item-content>
    </v-list-item>
   </div>
</template>
<script>
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import { quillEditor } from 'vue-quill-editor';
import main from '../assets/scripts/main.js';
export default {
  name: 'Home',
  components: {
    quillEditor
  },

  props: ['usuario'],
  data: () => ({
    editorOption: { /* quill options */ },
    loading: true,
    titulo: '',
    conteudo: '<p>example content</p>',
    artigos: [],
    dialogAddArtigo: false,
    dialogRemoveArtigo: false,
    dialogEditArtigo: false,
    articleToBeRemoved: null,
    articleToBeEdited: null,
    idToBeRemoved: null,
    idToBeEdited: null,
    snackbar: false,
    msgSnack: "",
  }),
  created(){
    this.getArtigos();
  },
  methods: {
    async getArtigos() {
      if(localStorage.getItem('cedeppeToken') != undefined && localStorage.getItem('cedeppeToken')  != null){
        var req = {
          token: localStorage.getItem('cedeppeToken')
        }
        var resp = await main.postApi(req, "artigos/pegarArtigos");
        var retorno = await resp.json();
        if(retorno.sucesso){
          this.artigos = retorno.corpo;
          this.loading = false;
          console.log(this.artigos)
        }
      }
    },
    async salvarArtigo() {
      if(localStorage.getItem('cedeppeToken') != undefined && localStorage.getItem('cedeppeToken')  != null){
        var req = {
          titulo: this.titulo,
          conteudo: this.conteudo,
          token: localStorage.getItem('cedeppeToken')
        }
        var resp = await main.postApi(req, "artigos/addArtigo");
        var retorno = await resp.json();

        if(retorno.sucesso){
          this.dialogAddArtigo = false;
          this.getArtigos();
        }
      }
    },
    async removeArtigo() {
      if(localStorage.getItem('cedeppeToken') != undefined && localStorage.getItem('cedeppeToken')  != null){
        var req = {id: this.idToBeRemoved, token: localStorage.getItem('cedeppeToken')}
        var resp = await main.postApi(req, "artigos/removeArtigo");
        var retorno = await resp.json();

        if(retorno.sucesso){
          this.dialogRemoveArtigo=false;
          this.getArtigos();
        }
      }
    },
    cancelRemove(){
      this.dialogRemoveArtigo=false;
      this.articleToBeRemoved=null;
      this.idToBeRemoved=null;
    },
    selectToBeRemoved(artigo){
      this.dialogRemoveArtigo=true;
      this.articleToBeRemoved=artigo.Titulo;
      this.idToBeRemoved=artigo.Id;
    }
  }
}
</script>
