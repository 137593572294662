<template>
  <div>
    <loadingTela v-if="carregando"/>
    <v-row>
      <v-col cols="12" lg="12">
        <v-card>
          <v-img
            src="../assets/acordo.png"
            gradient="to top, rgba(25,32,72,.7), rgba(25,32,72,.0)"
            style="width: 100% !important; height: 500px"
            dark
          >
          
            <v-card-text class="fill-height d-flex align-end">
              <v-row class="flex-column">
                <v-col>
                  <v-btn color="red"  @click="pedirCodigo()">Associar</v-btn>
                </v-col>
                <v-col cols="12" md="10" lg="8" xl="7">
                  <h2 class="text-h3 py-3" style="line-height: 1.2">
                   PROCESSO EMINENTEMENTE ASSOCIATIVO
                  </h2>
                </v-col>
               
              </v-row>
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="9">
        <v-card  style="padding: 20px">
        <v-btn color="red"  style="float: right;color: white" @click="pedirCodigo()">Associar</v-btn>
          <v-card-text>
            <strong class="text-h6">Adesão de profissionais e vantagens</strong>
            <p>
              Adesão vitalícia sem mensalidades e sem anuidades. Um único pagamento de R$ {{valor}} , para adesão vitalícia, e vem a ganhar entre 150 - 250 reais por hora em projetos organizacionais ? Claro que sim !
            </p>
            <p>
              Este é o momento de você passar a ter a sua EMPRESA de Treinamentos, Palestras e Consultorias.
            </p>

            <strong class="text-h6">Nossos associados</strong>
            <p>
              1 - Associam-se  para que sejam alçados a projetos diversos em suas áreas de especialidade e ao mesmo tempo usem os mais de 100 núcleos do CEDEPPE como SEUS, à proporção que se apresentem como Consultores Líderes do CEDEPPE.
            </p>
            <p>
              2 - Conhecem todas as oportunidades de ganhos que o CEDEPPE oferece desde o início. Gerenciam nossos treinamentos  em sua cidade, pois assim realizam os treinamentos como participantes e ganham financeiramente com eles.
            </p>
            <p>
              3 - Ensinam que o negócio CEDEPPE é profissional, transparente e que tem bases e etapas como o desenvolvimento e amadurecimento do negócio. 
            </p>
            <strong class="text-h6">Vantagens iniciais:</strong>
            <v-list-item-group
              selected=false
              active-class="pink--text"
              multiple
            >
              <template v-for="i in vantagens" >
                <v-list-item :key="i.id" >
                  <template >
                    <v-list-item-content>
                      <v-list-item-title><strong style="color: grey">{{i.titulo}}</strong></v-list-item-title>
                      <v-list-item-subtitle>{{i.text}}</v-list-item-subtitle>
                    </v-list-item-content>
                      
                    <v-list-item-action>
                      <v-list-item-action-text></v-list-item-action-text>
                      
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>


         
          </v-card-text>
         
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <div>
          <siderbar />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import main from '../assets/scripts/main.js';
import { collection, addDoc,  query, where, getDocs } from "firebase/firestore";
export default {
  name: "Associando",
  components: {
     siderbar: () => import("@/components/layout/sidebar"),
     loading: () => import("@/components/layout/loading"),
     loadingTela: () => import("@/components/layout/loadingTela"),
  },
  mounted() {   
    //this.carregaTextosPagina('Boa Tarde');
  },
   data: () => ({
      textosPagina: null,


      txtosPaginas: null,
      carregando: false,
      loadingTela: true,
      origem: null,
      valor: '',
      vantagens: [
      {
        id: "1",
        titulo: "Consultor(a) Líder do CEDEPPE.",
        text: "Não mais se apresente como desempregado(a) ou infeliz no atual trabalho Apresente-se como Consultor(a) Líder do CEDEPPE."
      },
      {
        id: "2",
        titulo: "Atividades ilibadas desde 1999.",
        text: "Apresente-se doravante como CONSULTOR(A) LÍDER CEDEPPE, um grupo com atividades ilibadas desde 1999"
      },
      {
        id: "3",
        titulo: "Aumento de sua visibilidade profissional",
        text: "Obterá imediato aumento de sua visibilidade profissional sem comprometer sua imagem diante de seu empregador, pois está associado  a um grupo de executivos, a um clube de serviços que investirá em divulgação de seus dados"
      },
      {
        id: "4",
        titulo: "Apresentação pessoal a públicos específicos.",
        text: "Terá seus dados em dezenas de sites do sistema, que servem para sua apresentação pessoal a públicos específicos, (ver https://www.cedeppe.com/conteudo_504.html)"
      },
      {
        id: "5",
        titulo: "Parceiro do CEDEPPE.",
        text: "Você será parceiro do CEDEPPE, com seus honorários em contrato personalizado (estará em sua Área Restrita) e também poderá dividir com ele os resultados financeiros líquidos."
      },
      {
        id: "6",
        titulo: "Mostre seus serviços.",
        text: "Mostre seus serviços administrando sua Área Restrita, com: foto, c. vitae, cartão de visitas, dados pessoais, local para publicar seus posts, local para publicar suas propostas de cursos, local para colocação de suas áreas de consultorias, ganhos de comissões em seu tempo livre, contrato de convênio com o CEDEPPE, se desejar"
      },
      {
        id: "7",
        titulo: "Apoio comercial.",
        text: "Receberá apoio comercial de nossa radioweb www.radioweb26000.net "
      },
      {
        id: "8",
        titulo: "Despesas pagas.",
        text: "Terá despesas pagas nas atividades de consultorias e ministração de palestras e cursos"
      },
      {
        id: "9",
        titulo: "Divulgação.",
        text: "Terá divulgação de dados profissionais e suporte operacional"
      },
      {
        id: "10",
        titulo: "Honorários.",
        text: "Terá honorários acima de todos os concorrentes"
      },
      {
        id: "11",
        titulo: "Publicar seus 'posts'.",
        text: "Terá a possibilidade de publicar seus 'posts' e  propor seus cursos nos sites do grupo"
      },
      {
        id: "12",
        titulo: "Resultados líquidos.",
        text: "Participará dos resultados líquidos de projetos indicados"
      },
      {
        id: "13",
        titulo: "Formar equipes.",
        text: "Poderá formar equipes ao seu redor"
      },
      {
        id: "14",
        titulo: "Apoio em representações.",
        text: "Terá apoio para fazer representações regionais e temáticas com ganhos"
      },
      {
        id: "15",
        titulo: "Sistema de apoio em varios aspectos.",
        text: "Nosso trabalho consiste em oferecer-lhe um SISTEMA de apoio em aspectos  comerciais  e financeiros. publicidade e formação de network visando aumentar suas atividades em parceria."
      },
      {
        id: "16",
        titulo: "Acessar sua Área Restrita.",
        text: "Depois do formulário de Cadastro, veja seu  endereço de e-mail para instruções de como acessar sua Área Restrita e finalizar a sua adesão"
      },
      {
        id: "17",
        titulo: "Informações na area restrita.",
        text: "Coloque em sua Area Restrita o seu Curriculum Vitae em texto corrido e de até 15 linhas e faça upload de foto de rosto pois sem esses 2 dados a sua adesão não será concluída corretamente"
      }
    ]
  }),
  props: {
    linguaSelecionada: null
  },
  watch: {
    async "linguaSelecionada"(lingua) {
      this.carregaTextosPagina(lingua);
    }
  },
  async created(){
    this.carregando = true;
    this.carregaTextosPagina(this.linguaSelecionada);
    var req = {
        produto: 'adesao'
      }
    var resp = await main.postApi(req, "stripe/pegarProduto");
    var retorno = await resp.json();
    this.produto = retorno.corpo;
    this.valor = this.produto.Preco.filter(x => x.nickname == 'total')[0].valorDivido;
    this.loadingTela = false
    const params = new URLSearchParams(window.location.search)
    if(params.get('origem') != null){
      this.origem = params.get('origem');
      localStorage.setItem('origem', params.get('origem'));
    }
    this.carregando = false;
  },
  methods: { 
    async sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async carregaTextosPagina(lingua){
      var db = firebase.firestore();
      const usuarios = collection(db, "paginas");
      const q = await query(usuarios, where("pagina", "==", "Associando"));
      const querySnapshot = await getDocs(q);
      if(querySnapshot.docs.length > 0){
        querySnapshot.forEach((docu) => { 
          this.textosPagina = docu.data()
        });
      }
    },
    pedirCodigo(){
       this.$router.push('Pagamento?tipo=Associacao') 
    },
   
  }



};
</script>
<style>
.v-list-item__subtitle{
  white-space: normal !important
}
.v-list-item__title{
  white-space: normal !important
}
</style>
