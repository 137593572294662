<template>
  <v-card color="basil" flat>
    <v-card>
      <v-progress-linear
        :active="loading"
        color="red"
        indeterminate
        rounded
        absolute
        height="5">
      </v-progress-linear>
      <v-data-table
        locale="pt-Br"
        :headers="headers"
        :items="items"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title style="font-size: 15px">
                  Deseja deletar essa despeza?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    Deletar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click=""
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-card>
  </template>
<script>

import main from '../assets/scripts/main.js';
export default {
  name: 'Home',
  components: {

  },
  props: ['usuario'],
  data: () => ({
    loading: true,
    userLogado: {
      color: '',
      icon: '',
      sobrenome: '',
      nome: '',
      cupom: '',
      cordenador: ''
    },
    dialogDelete: false,
    headers: [
      { text: 'Nome', value: 'Nome' },
      { text: 'Forma De Pagamento', value: 'FormaDePagamento' },
      { text: 'Status', value: 'Status' },
      { text: 'Produto', value: 'Titulo' },
      { text: 'Valor (R$)', value: 'Valor' },
      { text: 'ValorRecebido (R$)', value: 'ValorRecebido' },
      { text: 'T.I (R$)', value: 'ti' },
      { text: 'Radio (R$)', value: 'Radio' },
      { text: 'Dono Cupom (R$)', value: 'DonoCupom' },
      { text: 'CEDEPPE (R$)', value: 'CEDEPPE' },
      { text: 'Criacao', value: 'DataCriacao' },
      { text: 'Local', value: 'Localidade' }
    ],
    items: [],
  }),
  created(){
      this.getPagos();
  },
  methods: {
    async getPagos () {
      var eu = this;
      if(localStorage.getItem('cedeppeToken') != undefined && localStorage.getItem('cedeppeToken')  != null){
        var req = {
          token: localStorage.getItem('cedeppeToken'),
          direcao: 'Entrada',
          status: 'aprovados'
        };
        var resp = await main.postApi(req, "admin/pegarPagamentos");
        var retorno = await resp.json();
        if(retorno.sucesso){
          this.items = retorno.corpo;
          this.loading = false;
        }
        else{
          this.loading = false;
          this.snackbar = true;
          this.msgSnack = retorno.erros
        }
      }
      else{
        this.deslogarvariaveis();
      }
    },
    deleteItemConfirm(){},
  }
}
</script>
