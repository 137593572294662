<template>
<div>
<v-progress-linear :active="loading" color="red" indeterminate rounded absolute height="5"></v-progress-linear>

        <v-card style="margin-bottom: 10px; margin-top: 20px" >
        <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="ProdutoCarregado.codProduto"></v-list-item-title>
              <v-list-item-subtitle v-text="ProdutoCarregado.descricao"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card color="basil" flat >
<div v-for="i in precos">
  <input type="radio" :id="i.id" name="precoSelect"  v-bind:value="i.id" :checked="i.id == valorSelecionado" v-model="valorSelecionado">
  <label style="margin-left: 10px; font-weight: bold; color: grey">{{i.valorTela}} - {{i.nickname}}</label>
</div>
        </v-card>
        <v-btn  class="mr-1" color="red" style="color: white; margin-top: 20px" @click="salvarPreco(valorSelecionado)">
          Salvar
        </v-btn>

  <v-snackbar
    v-model="snackbar"
  >
    {{ msgSnack }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="pink"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Fechar
      </v-btn>
    </template>
  </v-snackbar>


   </div>   
</template>
<script>

import main from '../assets/scripts/main.js';
  export default {
    name: 'Home',
    components: {
      
    },
    
    props: ['usuario'],
    data: () => ({
      loading: true,
      produto: null,
      precos: [],
      ProdutoCarregado: {
        codProduto: '',
        descricao: ''
      },
      snackbar: false,
      msgSnack: "",
    }),
    created(){
      this.getPrecos();
    },
    methods: {
      async getPrecos() {
        if(localStorage.getItem('cedeppeToken') != undefined && localStorage.getItem('cedeppeToken')  != null){

          var req = {
            produto: 'adesao'
          };
          var resp = await main.postApi(req, "stripe/pegarProduto");
          var retorno = await resp.json();
          if(retorno.sucesso){
            this.produto = retorno.corpo;
            this.ProdutoCarregado.codProduto = this.produto.CodigoProduto;
            this.ProdutoCarregado.descricao = this.produto.Descricao;
            
            this.valorSelecionado = this.produto.CodigoPrecoAtivo;
            var req2 = {
              produto: retorno.corpo.CodigoProduto,
            };
            var resp2 = await main.postApi(req2, "stripe/pegarPrecos");
            var retorno2 = await resp2.json();
            this.precos =  retorno2.filter(x => x.nickname == 'total');
            this.loading = false;
          }
        }
      },
      async salvarPreco(preco){
        this.loading = true;
        var req = {
          codigopreco: preco,
          codigoproduto: this.ProdutoCarregado.codProduto
        }
        var resp = await main.postApi(req, "stripe/alterarPreco");
        var retorno = await resp.json();
        if(retorno.sucesso){
          this.snackbar = true;
          this.msgSnack = retorno.corpo
          this.loading = false;
        }
        else{
          this.snackbar = true;
          this.msgSnack = retorno.erros
        }
      }
    }
  }
</script>
