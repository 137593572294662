<template>
    <div>
        <v-dialog
        v-model="dialogRemovePage"
        persistent
        width="1200"
        height="1200"
      >
      <v-card>
          <v-card-title>
            <span class="text-h5">Remover Página</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <h2>{{ pageToBeRemoved }}</h2>
              <h3>Têm certeza que deseja remover página '{{ pageToBeRemoved }}'? Essa ação é irreversível.</h3>
              <h3>URL: \{{ modeloToBeRemoved }}</h3>
            </v-container>
          </v-card-text>
          <v-card-actions>
              <v-btn variant="tonal" style="margin: 5px;" @click="cancelRemove()">
              Cancelar
            </v-btn>
            <v-btn color="red" style="color: white; margin: 5px;" variant="tonal" @click="removePage()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
        <v-list-item
        v-for="pagina in paginas"
        :key="pagina.id"
        >
        <v-list-item-content>
        <v-row>
            <v-col style="text-align: left; margin-left: 20px;">
            <v-list-item-title  style="font-weight: bold; font-size: 18;">
                <h3>{{pagina.Titulo}}</h3>
            </v-list-item-title>
            <v-list-item-subtitle style="font-weight: bold; font-size: 18">
                <h3>URL: \{{pagina.modelo}}</h3>
            </v-list-item-subtitle>
            </v-col>
            <v-col style="text-align: right;">
            <v-btn  variant="tonal" style="margin: 5px" @click="selectToBeRemoved(pagina)">
                Remover
            </v-btn>
            </v-col>
        </v-row>
        <v-divider style="margin: 10px"></v-divider>
        </v-list-item-content>
        </v-list-item>
    </div>
</template>

<script>
import main from '@/assets/scripts/main.js';
export default {
  name: 'RemoverPagina',
    data () {
      return {
        paginas: [],
        dialogRemovePage: false,
        pageToBeRemoved: null,
        modeloToBeRemoved: null,
      }
    },
    created(){
        this.getPages();
    },
    methods: {
    async getPages() {
      if(localStorage.getItem('cedeppeToken') != undefined && localStorage.getItem('cedeppeToken')  != null){
        var req = {}
        var resp = await main.postApi(req, "paginas/getPages");
        var retorno = await resp.json();
        // Sorter
        const sorter = (a,b) => {
            if (a.Titulo.localeCompare(b.Titulo) === 0)
            return a.modelo.localeCompare(b.modelo)
            else
            return a.Titulo.localeCompare(b.Titulo)
        }
        // Save Pages
        if(retorno.sucesso){
          this.paginas = retorno.corpo.sort(sorter);
          console.log(this.paginas)
          this.loading = false;
        }
      }
    },
    async removePage() {
      if(localStorage.getItem('cedeppeToken') != undefined && localStorage.getItem('cedeppeToken')  != null){
        var req = {modelo: this.modeloToBeRemoved, token: localStorage.getItem('cedeppeToken')}
        var resp = await main.postApi(req, "paginas/deletePage");
        var retorno = await resp.json();
        if(retorno.sucesso){
          console.log(this.paginas)
          this.dialogRemovePage=false;
          this.getPages();
        }
      }
    },
    cancelRemove(){
      this.dialogRemovePage=false;
      this.pageToBeRemoved=null;
      this.modeloToBeRemoved=null;
    },
    selectToBeRemoved(pagina){
      this.dialogRemovePage=true;
      this.pageToBeRemoved=pagina.Titulo;
      this.modeloToBeRemoved=pagina.modelo;
    }
  }
  }
</script>