import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Associando from '../views/Associando.vue'
import PagamentoRecebido from '../views/PagamentoRecebido.vue'
import Admin from '../views/Admin.vue'
import Artigo from '../views/Artigo.vue'
import Treinamentos from '../views/Index.vue'
import Pagamento from '../views/Pagamento.vue'
import CriadorHome from '../views/CriadorHome.vue'
import Consultor from '../views/Consultor.vue'
import NovoAssociado from '../views/NovoAssociado.vue'
import Index from '../views/Index.vue'
import UserPage from '../views/UserPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/User',
    name: 'User',
    component: UserPage
  },
  {
    path: '/about',
    name: 'About',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/Associando',
    name: 'Associando',
    component: Associando
  },
  {
    path: '/PagamentoRecebido',
    name: 'PagamentoRecebido',
    component: PagamentoRecebido
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/Artigo',
    name: 'Artigo',
    component: Artigo
  },
  {
    path: '/Treinamentos',
    name: 'Treinamentos',
    component: Treinamentos
  },
  {
    path: '/Pagamento',
    name: 'Pagamento',
    component: Pagamento
  },
  {
    path: '/CriadorHome',
    name: 'CriadorHome',
    component: CriadorHome
  },
  {
    path: '/Index',
    name: 'Index',
    component: Index
  },
  {
    path: '/Consultor',
    name: 'Consultor',
    component: Consultor
  },
  {
    path: '/NovoAssociado',
    name: 'NovoAssociado',
    component: NovoAssociado
  },
  {
    path: '/Usuario',
    name: 'Usuário',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/User.vue')
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
