import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false


import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyAuaPjF3udf8gBrKkbFJjqJCfhK3_cQ5a0",
  authDomain: "cedeppe-web.firebaseapp.com",
  projectId: "cedeppe-web",
  storageBucket: "cedeppe-web.appspot.com",
  messagingSenderId: "635590682744",
  appId: "1:635590682744:web:1551a3dd3ab02b7d65e58d",
  measurementId: "G-4FENRH5FCF"
};


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
firebase.initializeApp(firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
import VueMask from 'v-mask'
Vue.use(VueMask);
import loader from "vue-ui-preloader";
Vue.use(loader)


import VueQuillEditor from 'vue-quill-editor'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
Vue.use(VueQuillEditor, /* { default global options } */)

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
