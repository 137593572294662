<template>
<div>
 <v-progress-linear :active="loading" color="red" indeterminate absolute rounded height="5"></v-progress-linear>
 <v-row>
  <v-col>
    <v-card-text>
          <strong class="text-h6">{{artigo.titulo}}</strong>
          <p class="card-text" v-html="artigo.conteudo"></p>
      </v-card-text>
    </v-col>
  </v-row>
 <v-row>
  <v-col>
    <v-card-text>
          <strong class="text-h8"> {{artigo.autor}}</strong>
          <p>
             {{dataAtualFormatada(artigo.dtcriacao)}}
          </p>
      </v-card-text>

    </v-col>
  </v-row>
</div>

</template>

<script>

import main from '../assets/scripts/main.js';
  export default {
    name: 'Home',
    components: {

    },
    data: () => ({
      loading: true,
      id: null,
      artigo: {
        titulo: '',
        conteudo: '',
        autor: '',
        dtcriacao: ''
      }
    }),
    created(){
      const params = new URLSearchParams(window.location.search)
      if(params.get('id') != null){
        this.id = params.get('id');
        this.pegarArtigos(params.get('id'))
      }
    },
    methods: {
      dataAtualFormatada(dataEnviada){
        return main.dataAtualFormatada(dataEnviada)
      },
      async pegarArtigos(id){
        this.loading = true;
        var req = {
          id: id
        }
        var resp = await main.postApi(req, "artigos/pegarArtigo");
        var retorno = await resp.json();

        if(retorno.sucesso){
          let artigo = retorno.corpo[0]
          this.artigo.titulo = artigo.Titulo
          this.artigo.conteudo = artigo.Conteudo
          this.artigo.dtcriacao = artigo.DtCriacao
          this.artigo.autor = artigo.Nome
          this.loading = false;
        }
        else{
          this.snackbar = true;
          this.msgSnack = retorno.erros
        }
      }
    }
  }
</script>
